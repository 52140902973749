<template>
  <v-dialog
    v-model="isModalOpen"
    width="600"
    max-width="60%"
    persistent
  >
    <v-card>
      <v-card-title>{{ $t("stockAdjustments.import") }}</v-card-title>
      <v-card-text>
        <v-file-input
          v-model="file"
          accept=".xlsx"
          label="Select Excel file"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          @click="closeModal"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          text
          @click="confirmImport"
          :disabled="!valid"
        >
          {{ $t("import") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          @click="getImportTemplate()"
          :loading="is_loading_csv"
        >
          {{ $t("download_excel_template") }}
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              text
              @click="showInstructionsDialog"
              v-on="on"
            >
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("hubs.info") }}</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="isInfoDialogVisible">
      <v-card>
        <v-card-title>{{ $t("hubs.info") }}</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("hub_id") }}</th>
                  <th class="text-left">{{ $t("hub_name") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(hub, index) in hubs"
                  :key="index"
                >
                  <td>{{ hub.id }}</td>
                  <td>{{ hub.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="isInfoDialogVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isConfirmationDialogVisible">
      <v-card>
        <v-card-title>{{ $t("stockAdjustments.confirm") }}</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="fileContent"
            item-key="product_id"
          >
            <template v-slot:item.hubs="{ item }">
              <span
                v-for="(hub, index) in item.hubs"
                :key="index"
                >{{ getHubNamebyId(hub) }}<br
              /></span>
            </template>
            <template v-slot:item.expiration_date="{ item }">
              {{ excelToDate(item.expiration_date) }}
            </template>
            <template v-slot:item.real_expiration_date="{ item }">
              {{ excelToDate(item.real_expiration_date) }}
            </template>
          </v-data-table>
          <v-alert
            v-if="importStatus === 'failed'"
            text
            prominent
            type="error"
            icon="mdi-cloud-alert"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t("transfers.import_error") }},<br />
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="cancelImport"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            text
            @click="sendImport"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import * as XLSX from "xlsx";
import slugify from "slugify";
import moment from "moment";
import transferProductService from "@/store/services/transfer-product-service";

export default {
  name: "ImportTransferModal",
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
  },

  data: () => ({
    file: null,
    isConfirmationDialogVisible: false,
    isInfoDialogVisible: false,
    is_loading_csv: false,
    fileContent: null,
    importStatus: null,
    headers: [
      { text: "tracking_number", value: "tracking_number" },
      { text: "product_code", value: "product_code" },
      { text: "name", value: "name" },
      { text: "from_hub_name", value: "from_hub_name" },
      { text: "to_hub_name", value: "to_hub_name" },
      { text: "by_lots", value: "by_lots" },
      { text: "quantity", value: "quantity" },
      { text: "lot_number", value: "lot_number" },
    ],
  }),

  computed: {
    valid() {
      return this.file !== null;
    },
    ...mapGetters({
      isLoadingHubs: "hubs/isLoadingHubs",
      hubs: "hubs/listHubs",
    }),
  },

  methods: {
    closeModal() {
      this.upload_error = "";
      this.is_uploading = false;
      this.file = null;
      this.toggleModal();
    },

    showInstructionsDialog() {
      this.isInfoDialogVisible = true;
    },

    async readExcelFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
          const slugifiedHeaders = headers.map((header) =>
            slugify(header, { lower: true, replacement: "_" })
          );
          const range = XLSX.utils.decode_range(worksheet["!ref"]);
          range.s.r += 1; // Exclude header row
          const json = XLSX.utils.sheet_to_json(worksheet, {
            header: slugifiedHeaders,
            range,
          });
          resolve(json);
        };
        reader.onerror = (e) => {
          reject(e);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async confirmImport() {
      try {
        const excelData = await this.readExcelFile(this.file);
        this.fileContent = excelData.reduce((acc, obj) => {
          const {
            tracking_number,
            product_code,
            name,
            from_hub_name,
            to_hub_name,
            by_lots,
            quantity,
            lot_number,
          } = obj;
          acc.push({
            tracking_number,
            product_code,
            name,
            from_hub_name,
            to_hub_name,
            by_lots,
            quantity,
            lot_number,
          });
          return acc;
        }, []);
        this.isConfirmationDialogVisible = true;
      } catch (error) {
        console.error(error);
      }
    },

    cancelImport() {
      this.isConfirmationDialogVisible = false;
      this.file = null;
      this.fileContent = [];
      this.importStatus = "null";
    },

    async sendImport() {
      try {
        this.is_uploading = true;
        this.importStatus = "uploading";
        console.log(this.file);
        await transferProductService.uploadCSV(this.file);
        await this.$store.dispatch("transferProducts/list", {
          itemsPerPage: 30,
          ...this.filters,
        });
        this.isConfirmationDialogVisible = false;
        this.toggleModal();
        this.file = null;
        this.$swal("", this.$t("import_transfer_success"), "success");
      } catch (error) {
        if (error?.response?.data?.file) {
          this.importStatus = "failed";
        } else {
          console.error(error);
          this.$store.dispatch("alerts/error", error?.response?.data?.message);
        }
      }
    },

    async getImportTemplate() {
      this.is_loading_csv = true;
      transferProductService
        .downloadTemplate()
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "stock_adjustment_import.xlsx");
          document.body.appendChild(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },

    getHubNamebyId(id) {
      const hub = this.hubs.find((hub) => hub.id === id);
      return hub ? hub.name : id + this.$t("hub_not_found");
    },
    excelToDate(excelDate) {
      const INVALID_DATE_MESSAGE = this.$t("invalid_date");
      if (typeof excelDate !== "number") {
        return INVALID_DATE_MESSAGE;
      }
      const date = moment((excelDate - 25569) * 86400 * 1000);
      if (!date.isValid()) {
        return INVALID_DATE_MESSAGE;
      }
      return date.format("DD/MM/YYYY");
    },
  },
  created() {
    this.$store.dispatch("hubs/list");
  },
};
</script>